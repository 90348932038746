import { Component, Vue, Watch } from 'vue-property-decorator';
import { Options } from '@/components/mi-dialog/MiDialog';

@Component<IdleWarningDialog>({})
export default class IdleWarningDialog extends Vue {
  protected idleCountdown: NodeJS.Timer | null = null;

  protected get isIdle() {
    return this.$store.state.idleVue.isIdle;
  }

  @Watch('isIdle')
  protected idleChanged() {
    if (this.isIdle) {
      this.$store.dispatch('openDialog', this.idleCountdownDialogOptions);
      this.idleCountdown = setTimeout(() => {
        this.$store.dispatch('closeDialog', this.idleCountdownDialogOptions);
        this.$router.push(`/reports/${this.$route.params.id}`);
      }, 120000); // 2 minute
    }
  }

  protected get idleCountdownDialogOptions(): Options {
    return {
      title: 'Bevestiging',
      text: 'Bent u nog actief bezig op deze pagina? U wordt anders binnen 2 minuut doorverwezen naar de dossier pagina.',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('general.yes').toString(),
          action: () => {
            clearTimeout(this.idleCountdown as NodeJS.Timer);
          },
        },
      },
    };
  }
}
