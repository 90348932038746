import { Report } from '@/models/Report';
import { Vue } from 'vue-property-decorator';
import { formatDate } from '@/support/String';

export class AlertBar extends Vue {
  public static setOfflineMessage(report: Report) {
    return `Dit dossier is offline gezet door ${report.offline_user && report.offline_user.name} op ${report.offline_at && report.offline_at !== this.databaseDate() ? formatDate(report.offline_at, 'dd-LL-yyyy') : ''} om ${report.offline_at && report.offline_at !== this.databaseDate() ? formatDate(report.offline_at, 'HH:mm') : ''}. Offline apparaat: ${report.offline_device}`;
  }

  public static setIsOpenMessage(report: Report) {
    return `Let op! ${report.last_update_user && report.last_update_user.name} heeft om ${report.last_updated_by_user_at && report.last_updated_by_user_at !== this.databaseDate() ? formatDate(report.last_updated_by_user_at, 'HH:mm') : ''} dit dossier geopend.`;
  }

  public static setIsLockedMessage(user: string) {
    return `Let op! Op dit moment heeft ${user} het dossier open.`;
  }

  public static databaseDate() {
    return '0000-00-00 00:00:00';
  }
}
