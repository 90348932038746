import { ReportStatus } from '@/support/ReportStatus';
import { Rpc } from '@/models/Rpc';
import { Component, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { formatDateSeconds } from '@/support/String';
import Storage from '@/support/Storage';
import { Options } from '@/components/mi-dialog/MiDialog';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import PlanningOrganizationDialog from '@/components/dialog/planning-organization-dialog/PlanningOrganizationDialog.vue';
import { Report as ReportModel, Media, Report, Type } from '@/models/Report';
import { HistoricalReport } from '@/models/HistoricalReport';
import { Panel, FileType } from '@/views/Reports/Report';
import { DateTime } from 'luxon';
import draggable from 'vuedraggable';
import { File as FileModel } from '@/models/File';
import { TableVisibility } from '@/components/data-table/DataTable';
import { NAMHistories } from '@/models/NAMHistories';
import CaseNumberDialog from '@/components/dialog/case-number-dialog/CaseNumberDialog.vue';
import RequestDialog from '@/components/dialog/request-dialog/RequestDialog.vue';
import { AlertBar } from '@/support/AlertBar';
import { Address } from '@/models/Address';
import { Organization } from '@/models/Organization';
import { User } from '@/models/User';
import Attachments, { AttachmentOptions } from '@/components/attachments/Attachments';
import { Lock } from '@/support/Lock';
import IdleWarningDialog from '@/components/IdleWarningDialog';
import { Aanvrager } from '@/models/Aanvrager';
import { cloneDeep, debounce } from 'lodash';
import { AanvragerModelKeyEnum } from '@/models/Aanvrager';

@Component<WorkPreparation>({
  components: {
    draggable,
    CaseNumberDialog,
    RequestDialog,
    PlanningOrganizationDialog,
  },
  filters: {
    dateFormat: (date: string) => {
      if (! date) { return ''; }
      return formatDateSeconds(date);
    },
  },
})
export default class WorkPreparation extends IdleWarningDialog {
  public $pageTitle = 'Werkvoorbereiding';

  // LOCK
  protected lock: Lock = new Lock();

  protected lockKey = '';

  protected reportModel: ReportModel = new ReportModel();

  protected reportId = '';

  protected isLoading = true;

  protected currentStep = 1;

  protected steps: string[] = ['1', '2', '3', '4'];

  protected currentHistoricalDamage: HistoricalReport | null = null;

  protected isCreatingHistoricalReport = false;

  protected historicalReports: HistoricalReport[] = [];

  protected historicalReport: HistoricalReport | null = null;

  protected panelHistoricalReport: Panel[] | null = [];

  protected isOpenPanels = false;

  protected isCreatingHistoricalReportFromNAM = false;

  protected isLoadinghistoricalDamagesDatatable = false;

  protected selectedHistoricalDamages: any = [];

  protected WerkvoorbereidingStatus = false;

  protected isCreatingHistoricalReportFromTCMG = false;

  protected selectedHistoricalReports: any = [];

  protected visibility: TableVisibility = {
    checkboxes: true,
    total: false,
    search: false,
  }

  protected perspectivePictures: Media[] = [];

  protected mapPictures: Media[] = [];

  protected attachments: Media[] = [];

  protected uploadQueue: File[] = [];

  protected postTimeOut: any | null = null;

  protected isEditingAttachment = false;

  protected selectedMedia: Media | null = null;

  protected isUploadingFileHistorical = false;

  protected isUploadingFile = false;

  protected uploadedFile = '';

  protected uploaded = false;

  protected fileCounter = 0;

  protected filesSuccesCount: number | null = null;

  protected fileTypes: FileType[] = [
    {
      label: 'Offerte',
      value: 'offerte',
    },
    {
      label: 'Addendum',
      value: 'addendum',
    },
    {
      label: 'Nader onderzoek',
      value: 'nader_onderzoek',
    },
  ];

  protected fileDescription = '';

  protected selectedFile: File | null = null;

  protected selectedAttachment: Media | null = null;

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  protected reportTypes: string[] = [
    'created',
    'planning',
    'smr_created',
    'werkvoorbereiding_rejected',
  ];

  // approval bar
  protected approvalDialogOpen = false;

  protected requestDialogOpen = false;

  protected editCaseNumberDialog = false;

  protected title = '';

  protected status = '';

  protected approval = '';

  protected isZienswijze = false;

  protected isStuwmeer = false;

  protected isObjection = false;

  protected isSubmitted = false;

  protected isValidDate = true;

  protected planningOrganizationDialogOpen = false;

  protected created() {
    window.onbeforeunload = () => {
      this.lock.unlock(this.lockKey);
    };
  }

  public mounted() {
    // LOCK
    this.createLock();

    this.reportId = this.$route.params.id;
    this.getStorageValues();
    this.initialize();
    this.$store.dispatch('saveAnswerPayload', null);
  }

  protected getStorageValues() {
    if (Storage.get('workPreparation_currentStep')) {
      this.currentStep = Number(Storage.get('workPreparation_currentStep'));
    }
  }

  protected destroyed() {
    this.lock.unlock(this.lockKey);
    Storage.delete('workPreparation_currentStep');
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Werkvoorbereiding' },
        ],
      });
  }

  protected initialize() {
    this.isLoading = true;

    this.reportModel
      .include(['damages', 'last_status_change_log', 'media'])
      .find(this.$route.params.id)
      .then((report: ReportModel) => {
        this.reportModel = report;
        this.isLoading = false;
        this.filterMedia();
        this.fetchHistoricalReports();
        this.initBreadcrumb();
        this.pingReport();
        this.showApprovalButtons();

        this.steps = this.reportModel.isVesNulmetingReport ? ['1', '2', '3'] : ['1', '2', '3', '4'];

        this.isAllowedToViewDialog = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected emptyUpdateReport() {
    new Rpc()
      .execute('report:bezwaar-check', {
        report: this.reportModel.uuid,
      })
      .then(() => {
        this.$router.push(`/planning-tool?report=${this.reportModel.uuid}`);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async createLock() {
    this.lockKey = `report:${this.$route.params.id}:editor:workpreparation`;
    await this.lock.checkLock(this.lockKey);
    await this.lock.initLock(this.lockKey);
    this.setAlert();
  }

  protected get isLockedByUser(): boolean {
    return this.lock.isLockedByUser;
  }

  protected setAlert() {
    if (this.lock.user) {
      this.$store.state.alert.message = AlertBar.setIsLockedMessage(this.lock.user);
      this.$store.state.alert.show = true;
    } else {
      this.$store.state.alert.show = false;
    }
  }

  protected showApprovalButtons() {
    this.isZienswijze = this.reportModel.hasStatus('zienswijze_werkvoorbereiding_rejected');
    if (this.reportModel.type) {
      this.isStuwmeer = this.reportModel.isStuwmeerRegelingReport;
      this.isObjection = this.reportModel.isBezwaarReport;
    }
  }

  protected get isRejected() {
    return this.reportModel.hasStatus(['werkvoorbereiding_rejected', 'zienswijze_werkvoorbereiding_rejected', 'objection_werkvoorbereiding_rejected', 'smr_werkvoorbereiding_rejected']);
  }

  protected get isSmr() {
    return this.reportModel.isStuwmeerRegelingReport;
  }

  protected get isOpnameTCMG(): boolean {
    return this.reportModel.hasStatus('opname_werkvoorbereiding_done');
  }

  protected get isMutatieWoningReport(): boolean {
    return this.reportModel.isMutatieWoningReport;
  }

  protected toggleApproveDialog(status: string, title: string, approval: string) {
    this.status = status;
    this.title = title;
    this.approval = approval;
    this.approval = approval;
    this.WerkvoorbereidingStatus = status === 'created';
    this.approvalDialogOpen = true;
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  protected pingReport() {
    if (! this.reportModel || ! this.reportModel.uuid) {
      return;
    }

    this.reportModel
      .pingReport(this.reportModel.uuid)
      .then((report: ReportModel) => {
      //
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected fetchHistoricalReports() {
    new HistoricalReport()
      .include(['files'])
      .filter({ report: this.reportModel.uuid })
      .all()
      .then((historicalReports: HistoricalReport[]) => {
        this.historicalReports = historicalReports;
        this.openAllHistoricReports();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected openAllHistoricReports() {
    this.panelHistoricalReport = [...Array((this.historicalReports as any).length).keys()].map((_) => [true]);
    this.isOpenPanels = true;
  }

  // Reset the panel
  protected closeAllHistoricReports() {
    this.panelHistoricalReport = [];
    this.isOpenPanels = false;
  }

  protected filterMedia() {
    if (! this.reportModel.media) {
      return;
    }

    this.perspectivePictures = this.reportModel.media.filter((media: Media) => media.type === 'picture');

    this.mapPictures = this.reportModel.media.filter((media: Media) => media.type === 'floorplan');

    this.attachments = this.reportModel.media.filter((media: Media) => media.type !== 'floorplan' && media.type !== 'picture');
  }

  protected get attachmentOptions(): AttachmentOptions {
    return {
      model: this.reportModel,
      type: 'attachments',
      showAddButton: true,
      showTrillingsButton: false,
      headers: [
        {
          text: 'Bijlage',
          value: 'file_name',
        },
        {
          text: 'Beschrijving',
          value: 'description',
        },
        {
          text: 'Zichtbaar in het rapport',
          value: 'show_in_report',
        },
        {
          text: 'Geüpload door',
          value: 'uploaded_by',
        },
        {
          text: 'Aangemaakt op',
          value: 'created_at',
        },
        {
          text: 'Acties',
          value: 'actions',
          class: 'actions',
        },
      ],
    };
  }

  protected editAttachment(attachment: Media) {
    const attachementContainer = (this.$refs.attachment as Attachments);
    if (! attachementContainer) { return; }
    attachementContainer.editAttachment(attachment);
  }

  protected deleteAttachment(attachment: Media) {
    const attachementContainer = (this.$refs.attachment as Attachments);
    if (! attachementContainer) { return; }
    attachementContainer.deleteAttachment(attachment);
  }

  protected editHistorical(historicalReport: HistoricalReport) {
    this.currentHistoricalDamage = historicalReport;
    this.isCreatingHistoricalReport = true;
  }

  protected deleteHistorical(historicalReport: HistoricalReport) {
    this.historicalReport = historicalReport;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteHistoricalReport);
  }

  protected saveHistorical() {
    this.fetchHistoricalReports();
    this.isCreatingHistoricalReport = false;
  }

  protected deleteHistoricalMedia(media: Media, historicalReport: HistoricalReport) {
    this.selectedMedia = media;
    this.historicalReport = historicalReport;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteImage);
  }

  protected get dialogOptionsDeleteImage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze bijlage wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            if (! this.historicalReport || ! this.selectedMedia) {
              return;
            }

            new HistoricalReport(this.historicalReport)
              .deleteMediaEndpoint(this.selectedMedia.uuid ? this.selectedMedia.uuid : '')
              .then((response: AxiosResponse) => {
                this.initialize();
                this.appendReport();
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedMedia = null;
            this.historicalReport = null;
          },
        },
      },
    };
  }

  protected parseDate(date: string) {
    if (! date) {
      return '';
    }
    if (date.length < 12) {
      return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd-MM-yyyy');
    }
    return DateTime.fromFormat(date, 'yyyy-MM-dd hh:mm:ss').toFormat('dd-MM-yyyy');
  }

  protected get dialogOptionsDeleteHistoricalReport(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze historische schade wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            if (! this.historicalReport) {
              return;
            }

            new HistoricalReport(this.historicalReport)
              .delete()
              .then((response: AxiosResponse) => {
                this.initialize();
                this.appendReport();
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedMedia = null;
            this.historicalReport = null;
          },
        },
      },
    };
  }

  protected addHistoricalMedia(historicalReport: HistoricalReport) {
    this.historicalReport = historicalReport;
    this.isUploadingFileHistorical = true;
  }

  protected handleItemDroppedHistorical(file: File) {
    if (! file) {
      return;
    }

    this.postDamageFile(file);
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.uploadQueue.push(file);
  }

  protected postFiles() {
    if (! this.uploadQueue.length) {
      return;
    }

    new ReportModel()
      .mediaEndpoint(this.reportModel.uuid ? this.reportModel.uuid : '')
      .create({ file: this.uploadQueue[0] })
      .then((response: AxiosResponse) => {
        const queueCopy = [...[], ...this.uploadQueue];
        queueCopy.shift();
        this.uploadQueue = queueCopy;

        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultiple(this.fileCounter);

        this.appendReport();
        if (this.uploadQueue.length <= 0) {
          this.fileCounter = 0;
          this.filesSuccesCount = fileUploadComponent.filesUploadedCount();
          this.initialize();
        } else {
          setTimeout(() => {
            this.fileCounter += 1;
            this.postFiles();
          }, 500);
        }
      })
      .catch((error: AxiosError) => {
        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
      });
  }

  protected changeStep(direction: string) {
    if (direction === 'next') {
      this.currentStep ++;
    } else if (direction === 'previous') {
      this.currentStep --;
    }
  }

  protected postDamageFile(file: File | string) {
    if (! this.historicalReport) {
      return;
    }

    new HistoricalReport()
      .mediaEndpoint(this.historicalReport.uuid ? this.historicalReport.uuid : '')
      .create({ file })
      .then((response: AxiosResponse) => {
        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploaded();
        this.initialize();
        this.appendReport();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected appendReport() {
    if (this.reportModel.status !== 'opname_werkvoorbereiding_done') {
      return;
    }

    new ReportModel()
      .append(this.reportModel.uuid)
      .then((response: AxiosResponse) => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected createHistoricalDamage() {
    const payload = {
      answers: {
        historicalReport_ABCSchadesAantallen: '',
        historicalReport_BIJZONDERHEDEN: '',
        historicalReport_BedragUitbetaling: '',
        historicalReport_Calculatieexpert: '',
        historicalReport_CaseNumber: '',
        historicalReport_DatumAkkoord: '',
        historicalReport_FinaleKwijting: '',
        historicalReport_KeuzeKUSKEAKHC: '',
        historicalReport_Naamexpert: '',
        historicalReport_OmschrijvingSchadelocatie: '',
        historicalReport_Opnamedatum: '',
        historicalReport_Status: '',
        historicalReport_Uitbetaald: '',
        historicalReport_Verslagdatum: '',
        historicalReport_ZelfdeEigenaar: '',
      },
      report: this.$route.params.id,
    };

    new HistoricalReport()
      .create(payload)
      .then((damage: HistoricalReport) => {
        this.currentHistoricalDamage = damage;
        this.isCreatingHistoricalReport = true;
        this.appendReport();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected closeiIsCreatingHistoricalReport() {
    this.isCreatingHistoricalReport = false;
    this.initialize();
  }

  protected closeIsCreatingHistoricalReportFromNAM() {
    this.isCreatingHistoricalReportFromNAM = false;
    this.selectedHistoricalDamages = [];
  }

  protected saveHistoricalDamagesFromNAM() {
    this.isLoading = true;
    const parsedSelectedHistoricalDamages = this.selectedHistoricalDamages.map((selectedHistoricalDamage: NAMHistories) => selectedHistoricalDamage.id);

    new HistoricalReport()
      .create({
        report: this.reportModel.uuid,
        nam_histories: parsedSelectedHistoricalDamages,
      })
      .then((response: any) => {
        this.isLoading = false;
        this.initialize();
        this.closeIsCreatingHistoricalReportFromNAM();
        this.appendReport();
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  get tableOptionsNAM(): Object {
    return {
      model: new NAMHistories().filter({ report: this.reportModel.uuid }),
      name: {
        singular: 'NAM bestand',
        plural: 'NAM bestanden',
      },
      headers: [
        {
          text: 'Dossiernummer',
          value: 'dossiernummer',
        },
        {
          text: 'Dossiertype',
          value: 'dossier_type',
        },
        {
          text: 'Dossier subtype',
          value: 'dossier_subtype',
        },
        {
          text: 'Postcode',
          value: 'postcode',
        },
        {
          text: 'Huisnummer',
          value: 'huisnummer',
        },
        {
          text: 'Huisnummer Toevoeging',
          value: 'huisnummer_toevoeging',
        },
        {
          text: 'Eigenaar',
          value: 'eigenaar',
        },
        {
          text: 'Bureau',
          value: 'naam_bureau',
        },
        {
          text: 'Deskundige',
          value: 'naam_schade_expert',
        },
      ],
    };
  }

  protected closeIsCreatingHistoricalReportFromTCMG() {
    this.isCreatingHistoricalReportFromTCMG = false;
    this.selectedHistoricalDamages = [];
  }

  protected saveHistoricalDamagesFromTCMG() {
    this.isLoading = true;
    const parsedSelectedHistoricalReports = this.selectedHistoricalReports.map((selectedHistoricalReport: HistoricalReport) => selectedHistoricalReport.uuid);

    new HistoricalReport()
      .create({
        report: this.reportModel.uuid,
        source_reports: parsedSelectedHistoricalReports,
      })
      .then((response: any) => {
        this.isLoading = false;
        this.initialize();
        this.closeIsCreatingHistoricalReportFromNAM();
        this.appendReport();
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  get tableOptionsTCMG(): Object {
    return {
      model: new Report().filter({ report: this.reportModel.uuid }).include('organization'),
      name: {
        singular: 'IMG dossiers',
        plural: 'IMG dossiers',
      },
      headers: [
        {
          text: 'Dossiernummer',
          value: 'case_number',
        },
        {
          text: 'Dossiertype',
          value: 'type',
          transform: (type: Type) => (type ? type.name : ''),
        },
        {
          text: 'Postcode',
          value: 'address',
          transform: (address: Address) => (address ? address.postcode : ''),
        },
        {
          text: 'Huisnummer',
          value: 'address',
          transform: (address: Address) => (address ? address.number : ''),
        },
        {
          text: 'Huisnummer Toevoeging',
          value: 'address',
          transform: (address: Address) => (address ? address.number_add : ''),
        },
        {
          text: 'Bureau',
          value: 'organization',
          transform: (organization: Organization) => (organization ? organization.name : ''),
        },
        {
          text: 'Deskundige',
          value: 'expert',
          transform: (expert: User) => (expert ? expert.name : ''),
        },
      ],
    };
  }

  protected get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: 'ghost',
    };
  }

  protected get uploadText() {
    if (! this.uploaded) {
      return 'Upload een bestand in:';
    }

    return 'Upload nog een bestand in:';
  }

  protected closeUploadingFile() {
    this.isUploadingFile = false;
    this.uploadedFile = '';
    this.selectedFile = null;
    this.fileCounter = 0;
    this.filesSuccesCount = null;
    this.initialize();
  }

  protected updateDamageOrder() {
    if (! this.historicalReports) {
      return;
    }

    const damageIds: string[] = this.historicalReports.map((historicalReport: HistoricalReport) => historicalReport.uuid as string);

    new HistoricalReport()
      .sortDamages(damageIds)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateAttachmentOrder(media: any, id: string) {
    const payload: string[] = [];
    media.forEach((media: any) => {
      payload.push(media.uuid);
    });

    new FileModel()
      .sortFiles(payload)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get isProduction(): boolean {
    return this.$store.state.isProduction;
  }

  protected get ReportStatus() {
    return ReportStatus;
  }

  @Watch('panelHistoricalReport')
  protected panelsChanged() {
    if (! this.panelHistoricalReport) {
      return;
    }

    if ((this.historicalReports as any).length === this.panelHistoricalReport.length) {
      const panelDamages = this.panelHistoricalReport.filter((item: any) => item[0] === true);

      if (panelDamages.length === (this.historicalReports as any).length) {
        this.isOpenPanels = true;
      } else {
        this.isOpenPanels = false;
      }
    }
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    //
  }

  @Watch('uploadQueue')
  public uploadQueueChanged(to: File[], from: File[]) {
    if (to.length < from.length) {
      return;
    }

    if (this.postTimeOut) {
      clearTimeout(this.postTimeOut);
    }

    this.postTimeOut = setTimeout(() => {
      this.postFiles();
    }, 20);
  }

  @Watch('currentStep')
  protected currentStepChanged() {
    Storage.set('workPreparation_currentStep', this.currentStep);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

interface Mask {
  [key: string]: string;
}
