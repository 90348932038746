var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "approval-dialog" },
        [
          _vm.isLoading
            ? _c("LoaderCard", {
                attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
              })
            : [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c(
                            "div",
                            { staticClass: "icon icon--small" },
                            [_c("v-icon", [_vm._v("edit")])],
                            1
                          )
                        ]),
                        _c("v-flex", { staticClass: "text-xs-center" }, [
                          _c("h2", [_vm._v(_vm._s(_vm.title))]),
                          _c("h2", [_vm._v(_vm._s(_vm.report.case_number))])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      {
                        staticClass: "approvalDialogContainer",
                        attrs: { row: "", wrap: "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "Dossier naam",
                                placeholder: "Vul een geldig AS- of S-nummer in"
                              },
                              model: {
                                value: _vm.caseNumber,
                                callback: function($$v) {
                                  _vm.caseNumber = $$v
                                },
                                expression: "caseNumber"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "buttonContainer" },
                  [
                    _c(
                      "v-layout",
                      { attrs: { "justify-space-between": "", row: "" } },
                      [
                        _c(
                          "MiButton",
                          {
                            attrs: { color: "text-light", outline: "true" },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.close($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$tc("general.cancel")) + " "
                            )
                          ]
                        ),
                        _c(
                          "MiButton",
                          {
                            staticClass: "float--right",
                            attrs: {
                              disabled: _vm.isDisabled,
                              color: "success"
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.changeCaseNumber($event)
                              }
                            }
                          },
                          [_vm._v(" aanpassen ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }