var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "defaultDialog" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "dialog-close",
                            on: { click: _vm.close }
                          },
                          on
                        ),
                        [_vm._v("close")]
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Sluiten")])]
          ),
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "align-end": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                    _c("h2", { staticClass: "dialog-title" }, [
                      _vm._v("Planning door Expertisebureau")
                    ])
                  ]),
                  _vm.isLoading
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("LoaderCard", {
                            attrs: { flat: "", type: "spinner--center" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.organizations,
                                label: "Expertisebureau",
                                "item-text": "name",
                                "item-value": "id"
                              },
                              model: {
                                value: _vm.selectedOrganization,
                                callback: function($$v) {
                                  _vm.selectedOrganization = $$v
                                },
                                expression: "selectedOrganization"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.users,
                                label: "Opnemer",
                                "item-text": "name",
                                "item-value": "uuid",
                                "hide-details": "",
                                clearable: "",
                                disabled: !_vm.users
                              },
                              model: {
                                value: _vm.selectedUser,
                                callback: function($$v) {
                                  _vm.selectedUser = $$v
                                },
                                expression: "selectedUser"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-5": "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  "justify-space-between": "",
                                  "align-center": ""
                                }
                              },
                              [
                                _c("v-flex", { attrs: { shrink: "" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fadedText link mr-3",
                                      on: { click: _vm.close }
                                    },
                                    [_vm._v("Annuleren")]
                                  )
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          disabled: !_vm.canCreate,
                                          color: "primary",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.create($event)
                                          }
                                        }
                                      },
                                      [_vm._v(" Doorzetten ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }