import { ReportStatus } from '@/support/ReportStatus';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';

@Component<PlanningOrganizationDialog>({})
export default class PlanningOrganizationDialog extends Vue {
  @Prop()
  protected report!: Report;

  protected isLoading = false;

  protected users: Organization[] | null = null;

  protected selectedUser = '';

  protected organizations: Organization[] | null = null;

  protected selectedOrganization = '';

  protected mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getOrganizations();
    this.isLoading = false;
  }

  protected async getExperts() {
    await new Organization()
      .include('experts')
      .find(this.selectedOrganization)
      .then((response: Organization) => {
        this.users = response.experts;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getOrganizations() {
    this.organizations = await new Organization()
      .getAllExperts()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected close() {
    this.$emit('input', false);
  }

  protected create() {
    if (! this.report || ! this.report.uuid) {
      return;
    }

    this.isLoading = true;

    const payload = {
      status: this.planningStatus,
      organization_expert: this.selectedUser,
      organization: this.selectedOrganization,
    };

    this.report
      .update(payload)
      .then(() => {
        this.$router.push(`/reports/${this.report.uuid}`);
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected get planningStatus() {
    if (this.report.isBezwaarReport) {
      return ReportStatus.OBJECTION_PENDING_ASSIGNMENT;
    }

    return ReportStatus.PLANNING_ORGANIZATION;
  }

  @Watch('selectedOrganization')
  protected selectedOrganizationChanged(to: any, from: any) {
    this.getExperts();
  }

  protected get canCreate() {
    if (this.report.isAannemersReport || this.report.isWoningCooperatie2ReportNew) {
      return this.selectedUser;
    }

    return true;
  }
}
