import { Lock as LockModel } from '@/models/Lock';
import store from '@/store';

export class Lock {
  private locked = false;

  private userName = '';

  private intervalTimer = 60000;

  protected checkLockInterval: NodeJS.Timer | null = null;

  protected setLockInterval: NodeJS.Timer | null = null;

  public async initLock(value: string) {
    this.checkLockInterval = setInterval(async () => {
      this.checkLock(value);
    }, this.intervalTimer);

    if (! this.isLockedByUser) {
      this.initIntervalLock(value);
    }
  }

  protected initIntervalLock(value: string) {
    this.setLockInterval = setInterval(async () => {
      this.setLock({ object: value });
    }, this.intervalTimer);

    clearInterval(this.checkLockInterval as NodeJS.Timer);
  }

  public async checkLock(value: string) {
    const payload = {
      object: value,
    };

    const checkLock = await new LockModel()
      .checkLock(payload);

    this.isLockedByUser = checkLock.is_locked;
    this.userName = checkLock.user;
    if (! checkLock.is_locked) {
      this.setLock(payload);
      store.state.alert.show = false;
    }
  }

  public setLock(payload: {[key: string]: string}) {
    new LockModel()
      .lock(payload);
  }

  public unlock(value: string) {
    new LockModel()
      .unlock({
        object: value,
      });

    clearInterval(this.checkLockInterval as NodeJS.Timer);
    clearInterval(this.setLockInterval as NodeJS.Timer);
  }

  public get isLockedByUser() {
    return this.locked;
  }

  public set isLockedByUser(value: boolean) {
    this.locked = value;
  }

  public get user() {
    return this.userName;
  }

  public set user(value: string) {
    this.userName = value;
  }
}
