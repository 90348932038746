import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Report } from '@/models/Report';
import { Rpc } from '@/models/Rpc';
import { startsWith } from 'lodash';

@Component<CaseNumberDialog>({})
export default class CaseNumberDialog extends Vue {
  public $pageTitle = 'Dossier nummer';

  protected actionDisabled = false;

  protected caseNumber = '';

  @Prop({ default: '' })
  protected title!: string;

  @Prop()
  protected report?: Report;

  protected isLoading = false;

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    //
  }

  protected close() {
    this.caseNumber = '';
    this.isLoading = false;
    this.$emit('closeDialog', this.report);
    this.$emit('input', false);
  }

  protected changeCaseNumber() {
    if (this.isDisabled || ! this.report) {
      return;
    }

    this.isLoading = true;
    const payload = {
      signature: 'report:edit-case-number',
      body: {
        case_number: this.report.case_number,
        new_case_number: this.caseNumber.toUpperCase(),
      },
    };

    new Rpc()
      .rpcPost(payload)
      .then((report: Report) => {
        this.close();
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  protected get isDisabled(): boolean {
    if (
      this.caseNumber.length
      && (
        startsWith(this.caseNumber.toUpperCase(), 'AS')
        || startsWith(this.caseNumber.toUpperCase(), 'S')
      )
    ) {
      return false;
    }
    return true;
  }
}
