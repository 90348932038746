var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container container--md-50 workPrepContainer" },
    [
      _vm.isAllowedToViewDialog
        ? _c("AllowedToView", {
            attrs: { report: _vm.reportModel, editor: "werkvoorbereiding" },
            on: { handleAlert: _vm.allowedToView }
          })
        : _vm._e(),
      _vm.isAllowedToView
        ? [
            _c(
              "div",
              {
                staticClass:
                  "elementPanel elementPanel__sub elementPanel__sub--withActionbar"
              },
              [
                _c(
                  "div",
                  { staticClass: "questionStepper" },
                  [
                    _c(
                      "v-stepper",
                      {
                        model: {
                          value: _vm.currentStep,
                          callback: function($$v) {
                            _vm.currentStep = $$v
                          },
                          expression: "currentStep"
                        }
                      },
                      [
                        _c(
                          "v-stepper-header",
                          [
                            _vm._l(_vm.steps.length, function(n) {
                              return [
                                _c(
                                  "v-stepper-step",
                                  {
                                    key: n + "-step",
                                    attrs: {
                                      color: "secondary",
                                      complete: _vm.currentStep > n,
                                      step: n,
                                      editable: "",
                                      "edit-icon": "check"
                                    }
                                  },
                                  [_vm._v(" Stap " + _vm._s(n) + " ")]
                                ),
                                n !== _vm.steps.length
                                  ? _c("v-divider", { key: n })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        ),
                        _c(
                          "v-stepper-items",
                          [
                            _c(
                              "v-stepper-content",
                              { attrs: { step: "1" } },
                              [
                                _vm.isLoading
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading
                                  ? _c(
                                      "div",
                                      { staticClass: "stepContainer" },
                                      [
                                        _c(
                                          "h2",
                                          { staticClass: "sectionTitle" },
                                          [_vm._v("Algemene gegevens")]
                                        ),
                                        _vm.reportId
                                          ? _c("DynamicForm", {
                                              attrs: {
                                                showApplicantSubmittedAtField: true,
                                                disabled: _vm.isLockedByUser,
                                                reportId: _vm.reportId,
                                                showTags: true,
                                                formTypes: ["tcmg"],
                                                appendKeys: ["GebouwWerk"],
                                                currentEditor:
                                                  "werkvoorbereiding",
                                                sections: [2, 100]
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-stepper-content",
                              { attrs: { step: "2" } },
                              [
                                _vm.isLoading
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading
                                  ? _c(
                                      "div",
                                      { staticClass: "stepContainer" },
                                      [
                                        [
                                          _c(
                                            "h2",
                                            { staticClass: "sectionTitle" },
                                            [_vm._v("Competentie matrix")]
                                          ),
                                          _c("Skills", {
                                            attrs: {
                                              type: "report",
                                              model: _vm.reportModel,
                                              forceEditable: true,
                                              canEdit: !_vm.isLockedByUser
                                            }
                                          }),
                                          _vm.reportId
                                            ? _c("DynamicForm", {
                                                attrs: {
                                                  reportId: _vm.reportId,
                                                  disabled: _vm.isLockedByUser,
                                                  sections: [0],
                                                  appendKeys: ["WVBPlanComment"]
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            !_vm.reportModel.isVesNulmetingReport
                              ? [
                                  _c(
                                    "v-stepper-content",
                                    { attrs: { step: "3" } },
                                    [
                                      _vm.isLoading
                                        ? _c("LoaderCard", {
                                            attrs: {
                                              flat: "",
                                              type: "spinner--center"
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.isLoading
                                        ? _c(
                                            "div",
                                            { staticClass: "stepContainer" },
                                            [
                                              _c(
                                                "h2",
                                                { staticClass: "sectionTitle" },
                                                [_vm._v("Historische schades")]
                                              ),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    wrap: "",
                                                    "py-3": "",
                                                    "align-center": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "flex-starving"
                                                    },
                                                    [
                                                      _c(
                                                        "MiButton",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isLockedByUser,
                                                            color:
                                                              "secondary-dark",
                                                            icon: "add",
                                                            outline: "true",
                                                            round: "true",
                                                            small: "true",
                                                            fontSize: "12"
                                                          },
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.createHistoricalDamage()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Schade " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "actions.add"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "flex-starving",
                                                      attrs: { "pl-1": "" }
                                                    },
                                                    [
                                                      _c(
                                                        "MiButton",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isLockedByUser,
                                                            color:
                                                              "secondary-dark",
                                                            icon: "add",
                                                            outline: "true",
                                                            round: "true",
                                                            small: "true",
                                                            fontSize: "12"
                                                          },
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.isCreatingHistoricalReportFromNAM = true
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Schades toevoegen obv NAM "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "flex-starving",
                                                      attrs: { "pl-1": "" }
                                                    },
                                                    [
                                                      _c(
                                                        "MiButton",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isLockedByUser,
                                                            color:
                                                              "secondary-dark",
                                                            icon: "add",
                                                            outline: "true",
                                                            round: "true",
                                                            small: "true",
                                                            fontSize: "12"
                                                          },
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.isCreatingHistoricalReportFromTCMG = true
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Schades toevoegen obv IMG "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.historicalReports &&
                                              _vm.historicalReports.length
                                                ? [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          wrap: "",
                                                          "py-3": "",
                                                          "align-center": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "text-xs-right handlepanel"
                                                          },
                                                          [
                                                            _vm.isOpenPanels
                                                              ? _c("v-flex", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "panel"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              _vm.closeAllHistoricReports
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " sluit alle "
                                                                          ),
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "keyboard_arrow_up"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              : _vm._e(),
                                                            !_vm.isOpenPanels
                                                              ? _c("v-flex", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "panel"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              _vm.openAllHistoricReports
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " open alle "
                                                                          ),
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "keyboard_arrow_down"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "draggable",
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            options: {
                                                              delay: 500
                                                            },
                                                            disabled:
                                                              _vm.isLockedByUser
                                                          },
                                                          on: {
                                                            start: function(
                                                              $event
                                                            ) {
                                                              _vm.drag = true
                                                            },
                                                            end: function(
                                                              $event
                                                            ) {
                                                              ;(_vm.drag = false),
                                                                _vm.updateDamageOrder()
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.historicalReports,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.historicalReports = $$v
                                                            },
                                                            expression:
                                                              "historicalReports"
                                                          }
                                                        },
                                                        "draggable",
                                                        _vm.dragOptions,
                                                        false
                                                      ),
                                                      [
                                                        _c(
                                                          "transition-group",
                                                          {
                                                            staticClass:
                                                              "damagePanelContainer"
                                                          },
                                                          _vm._l(
                                                            _vm.historicalReports,
                                                            function(
                                                              historicalReport,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "v-expansion-panel",
                                                                {
                                                                  key:
                                                                    historicalReport.uuid,
                                                                  staticClass:
                                                                    "damagePanel",
                                                                  attrs: {
                                                                    expand: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .panelHistoricalReport[
                                                                        index
                                                                      ],
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.panelHistoricalReport,
                                                                        index,
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "panelHistoricalReport[index]"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-expansion-panel-content",
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          attrs: {
                                                                            slot:
                                                                              "header"
                                                                          },
                                                                          slot:
                                                                            "header"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                index +
                                                                                  1
                                                                              ) +
                                                                              ". " +
                                                                              _vm._s(
                                                                                historicalReport.name
                                                                                  ? historicalReport.name
                                                                                  : "Historische schade " +
                                                                                      (index +
                                                                                        1)
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-card",
                                                                        {
                                                                          staticStyle: {
                                                                            "border-top":
                                                                              "1px solid #ddd",
                                                                            "border-bottom":
                                                                              "1px solid #ddd"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-card-text",
                                                                            [
                                                                              !_vm.isLockedByUser
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "editContainer"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "edit",
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.editHistorical(
                                                                                                historicalReport
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "edit"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "delete",
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.deleteHistorical(
                                                                                                historicalReport
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "delete"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              historicalReport.answers
                                                                                ? _c(
                                                                                    "v-layout",
                                                                                    {
                                                                                      staticClass:
                                                                                        "item__list item__list--reports",
                                                                                      attrs: {
                                                                                        wrap:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "label",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs10:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Algemeen"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Status:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          historicalReport
                                                                                                            .answers
                                                                                                            .historicalReport_Status
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Zelfde eigenaar:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          historicalReport
                                                                                                            .answers
                                                                                                            .historicalReport_ZelfdeEigenaar
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Opnamedatum:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm.parseDate(
                                                                                                              historicalReport
                                                                                                                .answers
                                                                                                                .historicalReport_Opnamedatum
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Verslagdatum:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm.parseDate(
                                                                                                              historicalReport
                                                                                                                .answers
                                                                                                                .historicalReport_Verslagdatum
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Naam expert:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          historicalReport
                                                                                                            .answers
                                                                                                            .historicalReport_Naamexpert
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              historicalReport.answers
                                                                                ? _c(
                                                                                    "v-layout",
                                                                                    {
                                                                                      staticClass:
                                                                                        "item__list item__list--reports",
                                                                                      attrs: {
                                                                                        wrap:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "label",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs10:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Schade"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "ABC:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            historicalReport
                                                                                                              .answers
                                                                                                              .historicalReport_ABCSchadesAantallen
                                                                                                          ) +
                                                                                                          " "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Omschrijving:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            historicalReport
                                                                                                              .answers
                                                                                                              .historicalReport_OmschrijvingSchadelocatie
                                                                                                          ) +
                                                                                                          " "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Calculatie expert:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm._f(
                                                                                                              "currency"
                                                                                                            )(
                                                                                                              historicalReport
                                                                                                                .answers
                                                                                                                .historicalReport_Calculatieexpert
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Keuze KUS / KEA / KH:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          historicalReport
                                                                                                            .answers
                                                                                                            .historicalReport_KeuzeKUSKEAKHC
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Datum akkoord:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm.parseDate(
                                                                                                              historicalReport
                                                                                                                .answers
                                                                                                                .historicalReport_DatumAkkoord
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Finale kwijting:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          historicalReport
                                                                                                            .answers
                                                                                                            .historicalReport_FinaleKwijting
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Uitbetaald:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          historicalReport
                                                                                                            .answers
                                                                                                            .historicalReport_Uitbetaald
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Bedrag uitbetaling:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm._f(
                                                                                                              "currency"
                                                                                                            )(
                                                                                                              historicalReport
                                                                                                                .answers
                                                                                                                .historicalReport_BedragUitbetaling
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Nulmeting vereist:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          historicalReport
                                                                                                            .answers
                                                                                                            .historicalReport_NulMetingLight
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "list__item",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  "",
                                                                                                "align-center":
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs7:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Bijzonderheden:"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs5:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          historicalReport
                                                                                                            .answers
                                                                                                            .historicalReport_BIJZONDERHEDEN
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              historicalReport.media &&
                                                                              historicalReport
                                                                                .media
                                                                                .length
                                                                                ? _c(
                                                                                    "v-layout",
                                                                                    {
                                                                                      staticClass:
                                                                                        "item__list item__list--reports",
                                                                                      attrs: {
                                                                                        wrap:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          staticClass:
                                                                                            "label",
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-layout",
                                                                                            {
                                                                                              attrs: {
                                                                                                wrap:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-flex",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    xs10:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Bijlage"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          attrs: {
                                                                                            xs12:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "draggable",
                                                                                            _vm._b(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "list-group",
                                                                                                attrs: {
                                                                                                  disabled:
                                                                                                    _vm.isLockedByUser
                                                                                                },
                                                                                                on: {
                                                                                                  start: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    _vm.drag = true
                                                                                                  },
                                                                                                  end: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    ;(_vm.drag = false),
                                                                                                      _vm.updateAttachmentOrder(
                                                                                                        historicalReport.media,
                                                                                                        historicalReport.uuid
                                                                                                      )
                                                                                                  }
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    historicalReport.media,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      historicalReport,
                                                                                                      "media",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "historicalReport.media"
                                                                                                }
                                                                                              },
                                                                                              "draggable",
                                                                                              _vm.dragOptions,
                                                                                              false
                                                                                            ),
                                                                                            [
                                                                                              _c(
                                                                                                "transition-group",
                                                                                                _vm._l(
                                                                                                  historicalReport.media,
                                                                                                  function(
                                                                                                    media
                                                                                                  ) {
                                                                                                    return _c(
                                                                                                      "v-flex",
                                                                                                      {
                                                                                                        key:
                                                                                                          media.file_name,
                                                                                                        staticClass:
                                                                                                          "list__item",
                                                                                                        attrs: {
                                                                                                          xs12:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-layout",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              wrap:
                                                                                                                "",
                                                                                                              "align-center":
                                                                                                                ""
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-flex",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  xs10:
                                                                                                                    ""
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "item__name item__link"
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "a",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          href:
                                                                                                                            media.original,
                                                                                                                          target:
                                                                                                                            "_blank"
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          " " +
                                                                                                                            _vm._s(
                                                                                                                              media.file_name
                                                                                                                            ) +
                                                                                                                            " "
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-flex",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "text-xs-right",
                                                                                                                attrs: {
                                                                                                                  xs2:
                                                                                                                    ""
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "item__icon item__icon--action"
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "a",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          href:
                                                                                                                            media.original,
                                                                                                                          target:
                                                                                                                            "_blank"
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-icon",
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              "cloud_download"
                                                                                                                            )
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    )
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "item__icon item__icon--action delete"
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-icon",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          disabled:
                                                                                                                            _vm.isLockedByUser
                                                                                                                        },
                                                                                                                        on: {
                                                                                                                          click: function(
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            return _vm.deleteHistoricalMedia(
                                                                                                                              media,
                                                                                                                              historicalReport
                                                                                                                            )
                                                                                                                          }
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          " delete "
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  }
                                                                                                ),
                                                                                                1
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "v-layout",
                                                                                {
                                                                                  attrs: {
                                                                                    "pa-3":
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    [
                                                                                      _c(
                                                                                        "MiButton",
                                                                                        {
                                                                                          attrs: {
                                                                                            disabled:
                                                                                              _vm.isLockedByUser,
                                                                                            color:
                                                                                              "secondary-dark",
                                                                                            icon:
                                                                                              "add",
                                                                                            outline:
                                                                                              "true",
                                                                                            round:
                                                                                              "true",
                                                                                            small:
                                                                                              "true"
                                                                                          },
                                                                                          nativeOn: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.addHistoricalMedia(
                                                                                                historicalReport
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Bijlage " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "actions.add"
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e(),
                                              _vm.historicalReports.length <= 0
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fadedText"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Er zijn geen historische schades"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            _c(
                              "v-stepper-content",
                              {
                                attrs: {
                                  step: _vm.reportModel.isVesNulmetingReport
                                    ? 3
                                    : 4
                                }
                              },
                              [
                                _vm.isLoading
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading
                                  ? _c(
                                      "div",
                                      { staticClass: "stepContainer" },
                                      [
                                        _c(
                                          "h2",
                                          { staticClass: "sectionTitle" },
                                          [_vm._v("Bijlagen")]
                                        ),
                                        !_vm.isLoading
                                          ? _c("Attachments", {
                                              ref: "attachment",
                                              attrs: {
                                                options: _vm.attachmentOptions,
                                                report: _vm.reportModel,
                                                allowEdit: !_vm.isLockedByUser
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "item.file_name",
                                                    fn: function(item) {
                                                      return [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                item.original,
                                                              target: "_blank"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "concatenateString"
                                                                  )(
                                                                    item.file_name
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.created_at",
                                                    fn: function(item) {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "dateFormatTime"
                                                              )(item.created_at)
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.show_in_report",
                                                    fn: function(item) {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.show_in_report ==
                                                                "0"
                                                                ? "nee"
                                                                : item.show_in_report ==
                                                                  "1"
                                                                ? "ja"
                                                                : ""
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.actions",
                                                    fn: function(item) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "tw-flex tw-flex-row tw-flex-no-wrap tw-gap-8"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "item__icon item__icon--action"
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        item.original,
                                                                      target:
                                                                        "_blank"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "cloud_download"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            !_vm.isLockedByUser
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "item__icon item__icon--action"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.editAttachment(
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "edit"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            !_vm.isLockedByUser
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "item__icon item__icon--action delete"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteAttachment(
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "delete"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2379704425
                                              )
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          2
                        ),
                        _c(
                          "v-layout",
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs6: "" } },
                              [
                                _vm.currentStep > 1
                                  ? _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "secondary",
                                          outline: "true",
                                          icon: "keyboard_arrow_left"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.changeStep("previous")
                                          }
                                        }
                                      },
                                      [_vm._v(" Vorige stap ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { staticClass: "text-right", attrs: { xs6: "" } },
                              [
                                _vm.currentStep < 4
                                  ? _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "secondary",
                                          icon: "navigate_next"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.changeStep("next")
                                          }
                                        }
                                      },
                                      [_vm._v(" Volgende stap ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c("ApprovalBar", {
              attrs: { backUrl: "/reports/" + _vm.reportModel.uuid },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        !_vm.isObjection
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled: _vm.isLockedByUser,
                                      color: "primary",
                                      small: "true",
                                      icon: "undo"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleApproveDialog(
                                          "created",
                                          "Terugzetten naar:",
                                          "approved"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Naar Werkvoorbereiding ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isOpnameTCMG && !_vm.isObjection
                          ? [
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "alert",
                                        small: "true",
                                        icon: "send"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.requestDialogOpen = true
                                        }
                                      }
                                    },
                                    [_vm._v(" Uitvraag ")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        !_vm.isObjection
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled: _vm.isLockedByUser,
                                      color: "magenta",
                                      small: "true",
                                      icon: "send"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.planningOrganizationDialogOpen = true
                                      }
                                    }
                                  },
                                  [_vm._v(" Planning door Expertisebureau ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isObjection &&
                        !_vm.isStuwmeer &&
                        !_vm.isZienswijze &&
                        !_vm.isOpnameTCMG
                          ? [
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.isValidDate ||
                                          _vm.isLockedByUser,
                                        color: "success",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.isRejected
                                            ? _vm.toggleApproveDialog(
                                                "in_progress",
                                                "Versturen naar:",
                                                "approved"
                                              )
                                            : _vm.toggleApproveDialog(
                                                "planning",
                                                "Versturen naar:",
                                                "approved"
                                              )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isRejected
                                              ? "Naar expertisebureau"
                                              : "Naar planning"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isZienswijze
                          ? [
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.isValidDate ||
                                          _vm.isLockedByUser,
                                        color: "success",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            "zienswijze_in_progress",
                                            "Versturen naar:",
                                            "approved"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isRejected
                                              ? "Naar expertisebureau"
                                              : "Verander naar:"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isStuwmeer
                          ? [
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.isValidDate ||
                                          _vm.isLockedByUser,
                                        color: "success",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.isRejected
                                            ? _vm.toggleApproveDialog(
                                                "smr_in_progress",
                                                "Versturen naar:",
                                                "approved"
                                              )
                                            : _vm.toggleApproveDialog(
                                                "smr_created",
                                                "Versturen naar:",
                                                "approved"
                                              )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isRejected
                                              ? "Naar expertisebureau"
                                              : "Opslaan"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isObjection
                          ? [
                              _vm.reportModel.last_status_change_log
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _vm.reportModel.hasStatus(
                                        _vm.ReportStatus
                                          .OBJECTION_WERKVOORBEREIDING_REJECTED
                                      )
                                        ? _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                disabled: _vm.isLockedByUser,
                                                color: "warning",
                                                icon: "undo",
                                                small: "true"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.toggleApproveDialog(
                                                    _vm.reportModel
                                                      .last_status_change_log
                                                      .changed_from,
                                                    "Terugzetten naar:",
                                                    "approved"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Dossier terugzetten ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "alert",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.planningOrganizationDialogOpen = true
                                        }
                                      }
                                    },
                                    [_vm._v(" Naar Expertisebureau ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "success",
                                        icon: "schedule",
                                        small: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.emptyUpdateReport()
                                        }
                                      }
                                    },
                                    [_vm._v(" Dossier inplannen ")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isOpnameTCMG
                          ? [
                              _vm.isMutatieWoningReport
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.isValidDate ||
                                              _vm.isLockedByUser,
                                            color: "primary",
                                            small: "true",
                                            icon: "edit"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              _vm.editCaseNumberDialog = true
                                            }
                                          }
                                        },
                                        [_vm._v(" Dossiernummer aanpassen ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "success",
                                        disabled:
                                          _vm.isMutatieWoningReport ||
                                          !_vm.isValidDate ||
                                          _vm.isLockedByUser,
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            "opname_done",
                                            "Versturen naar:",
                                            "approved"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Naar Opname afgerond ")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2836369464
              )
            }),
            _vm.editCaseNumberDialog
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500" },
                    model: {
                      value: _vm.editCaseNumberDialog,
                      callback: function($$v) {
                        _vm.editCaseNumberDialog = $$v
                      },
                      expression: "editCaseNumberDialog"
                    }
                  },
                  [
                    _c("CaseNumberDialog", {
                      attrs: {
                        report: _vm.reportModel,
                        title: "Huidige dossier wijzigen naam:"
                      },
                      on: { closeDialog: _vm.initialize },
                      model: {
                        value: _vm.editCaseNumberDialog,
                        callback: function($$v) {
                          _vm.editCaseNumberDialog = $$v
                        },
                        expression: "editCaseNumberDialog"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.requestDialogOpen
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500" },
                    model: {
                      value: _vm.requestDialogOpen,
                      callback: function($$v) {
                        _vm.requestDialogOpen = $$v
                      },
                      expression: "requestDialogOpen"
                    }
                  },
                  [
                    _c("RequestDialog", {
                      attrs: {
                        report: _vm.reportModel,
                        title: "Uitvraag planning"
                      },
                      model: {
                        value: _vm.requestDialogOpen,
                        callback: function($$v) {
                          _vm.requestDialogOpen = $$v
                        },
                        expression: "requestDialogOpen"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.approvalDialogOpen
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500" },
                    model: {
                      value: _vm.approvalDialogOpen,
                      callback: function($$v) {
                        _vm.approvalDialogOpen = $$v
                      },
                      expression: "approvalDialogOpen"
                    }
                  },
                  [
                    _c("ApprovalDialog", {
                      attrs: {
                        report: _vm.reportModel,
                        status: _vm.status,
                        chooseOrganization:
                          !_vm.isObjection &&
                          !_vm.isStuwmeer &&
                          !_vm.isZienswijze &&
                          !_vm.WerkvoorbereidingStatus,
                        role: "veldwerk",
                        approval: _vm.approval,
                        planning: false,
                        title: _vm.isRejected
                          ? "Zet door naar expertisebureau"
                          : "Verander naar:"
                      },
                      model: {
                        value: _vm.approvalDialogOpen,
                        callback: function($$v) {
                          _vm.approvalDialogOpen = $$v
                        },
                        expression: "approvalDialogOpen"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCreatingHistoricalReport
              ? _c(
                  "v-dialog",
                  {
                    attrs: { width: "700px" },
                    model: {
                      value: _vm.isCreatingHistoricalReport,
                      callback: function($$v) {
                        _vm.isCreatingHistoricalReport = $$v
                      },
                      expression: "isCreatingHistoricalReport"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "elementPanel__header",
                                attrs: { row: "", wrap: "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "header__title",
                                    attrs: { sm11: "" }
                                  },
                                  [
                                    _c("h2", [
                                      _vm._v("Historische schade toevoegen")
                                    ])
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "header__close",
                                    attrs: { sm1: "" }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.closeiIsCreatingHistoricalReport(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("cancel")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "scrollContainer" },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "", "justify-center": "" } },
                              [
                                _vm.reportId
                                  ? _c("DynamicForm", {
                                      attrs: {
                                        reportId: _vm.reportId,
                                        historicalDamage:
                                          _vm.currentHistoricalDamage,
                                        formTypes: ["expert"],
                                        sections: 31
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "scrollContainer__actions" },
                          [
                            _c(
                              "v-container",
                              { attrs: { fluid: "", "grid-list-lg": "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "justify-space-between": "",
                                      row: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "text-light",
                                          outline: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.closeiIsCreatingHistoricalReport(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("actions.cancel")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "secondary-dark",
                                          icon: "save"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.saveHistorical($event)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("actions.save")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCreatingHistoricalReportFromNAM
              ? _c(
                  "v-dialog",
                  {
                    attrs: { width: "70vw" },
                    model: {
                      value: _vm.isCreatingHistoricalReportFromNAM,
                      callback: function($$v) {
                        _vm.isCreatingHistoricalReportFromNAM = $$v
                      },
                      expression: "isCreatingHistoricalReportFromNAM"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _vm.isLoading
                          ? _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          : _vm._e(),
                        !_vm.isLoading
                          ? [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "elementPanel__header",
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "header__title",
                                          attrs: { sm11: "" }
                                        },
                                        [
                                          _c("h2", [
                                            _vm._v(
                                              "Historische schades toevoegen obv NAM lijst"
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "header__close",
                                          attrs: { sm1: "" }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.closeIsCreatingHistoricalReportFromNAM(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("cancel")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "scrollContainer" },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: { wrap: "", "justify-center": "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { x12: "" } },
                                        [
                                          _vm.isCreatingHistoricalReportFromNAM
                                            ? _c("DataTable", {
                                                ref:
                                                  "historicalDamagesDatatable",
                                                attrs: {
                                                  options: _vm.tableOptionsNAM,
                                                  visibility: _vm.visibility
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedHistoricalDamages,
                                                  callback: function($$v) {
                                                    _vm.selectedHistoricalDamages = $$v
                                                  },
                                                  expression:
                                                    "selectedHistoricalDamages"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "scrollContainer__actions" },
                                [
                                  _c(
                                    "v-container",
                                    {
                                      attrs: { fluid: "", "grid-list-lg": "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            "justify-space-between": "",
                                            row: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "text-light",
                                                outline: "true"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.closeIsCreatingHistoricalReportFromNAM(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("actions.cancel")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "secondary-dark",
                                                icon: "save"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.saveHistoricalDamagesFromNAM(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Toevoegen ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCreatingHistoricalReportFromTCMG
              ? _c(
                  "v-dialog",
                  {
                    attrs: { width: "70vw" },
                    model: {
                      value: _vm.isCreatingHistoricalReportFromTCMG,
                      callback: function($$v) {
                        _vm.isCreatingHistoricalReportFromTCMG = $$v
                      },
                      expression: "isCreatingHistoricalReportFromTCMG"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _vm.isLoading
                          ? _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          : _vm._e(),
                        !_vm.isLoading
                          ? [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "elementPanel__header",
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "header__title",
                                          attrs: { sm11: "" }
                                        },
                                        [
                                          _c("h2", [
                                            _vm._v(
                                              "Historische schades toevoegen obv IMG"
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "header__close",
                                          attrs: { sm1: "" }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.closeIsCreatingHistoricalReportFromTCMG(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("cancel")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "scrollContainer" },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: { wrap: "", "justify-center": "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { x12: "" } },
                                        [
                                          _vm.isCreatingHistoricalReportFromTCMG
                                            ? _c("DataTable", {
                                                ref:
                                                  "historicalReportsDatatable",
                                                attrs: {
                                                  options: _vm.tableOptionsTCMG,
                                                  itemKey: "uuid",
                                                  visibility: _vm.visibility
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedHistoricalReports,
                                                  callback: function($$v) {
                                                    _vm.selectedHistoricalReports = $$v
                                                  },
                                                  expression:
                                                    "selectedHistoricalReports"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "scrollContainer__actions" },
                                [
                                  _c(
                                    "v-container",
                                    {
                                      attrs: { fluid: "", "grid-list-lg": "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            "justify-space-between": "",
                                            row: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "text-light",
                                                outline: "true"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.closeIsCreatingHistoricalReportFromTCMG(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("actions.cancel")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "secondary-dark",
                                                icon: "save"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.saveHistoricalDamagesFromTCMG(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Toevoegen ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isUploadingFile
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "750" },
                    model: {
                      value: _vm.isUploadingFile,
                      callback: function($$v) {
                        _vm.isUploadingFile = $$v
                      },
                      expression: "isUploadingFile"
                    }
                  },
                  [
                    _c("v-card", [
                      _c(
                        "div",
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "elementPanel__header",
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__title",
                                      attrs: { sm11: "" }
                                    },
                                    [
                                      _c("h2", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isEditingAttachment
                                                ? "Bijlage aanpassen"
                                                : "Bijlage toevoegen"
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__close",
                                      attrs: { sm1: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.closeUploadingFile(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("cancel")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _vm.filesSuccesCount !== null
                                ? _c("span", [
                                    _vm._v(" Totaal toegevoegde bijlagen: "),
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.filesSuccesCount))
                                    ])
                                  ])
                                : _vm._e(),
                              !_vm.isEditingAttachment
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass: "fileUpload__container",
                                      attrs: {
                                        "fill-height": "",
                                        "mb-3": "",
                                        "pa-1": "",
                                        wrap: ""
                                      }
                                    },
                                    [
                                      _c("MiFileUpload", {
                                        ref: "fileUpload",
                                        attrs: {
                                          filePath: _vm.uploadedFile,
                                          clearAfterUpload: false,
                                          multiple: true
                                        },
                                        on: {
                                          itemDropped: function($event) {
                                            return _vm.handleItemDropped($event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm.isUploadingFileHistorical
              ? _c(
                  "v-dialog",
                  {
                    attrs: { persistent: true, "max-width": "750" },
                    model: {
                      value: _vm.isUploadingFileHistorical,
                      callback: function($$v) {
                        _vm.isUploadingFileHistorical = $$v
                      },
                      expression: "isUploadingFileHistorical"
                    }
                  },
                  [
                    _c("v-card", [
                      _c(
                        "div",
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "elementPanel__header",
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__title",
                                      attrs: { sm11: "" }
                                    },
                                    [_c("h2", [_vm._v("Media toevoegen")])]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__close",
                                      attrs: { sm1: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              _vm.isUploadingFileHistorical = false
                                            }
                                          }
                                        },
                                        [_vm._v("cancel")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                { staticClass: "createDialog" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("p", [
                                          _vm._v(
                                            " " + _vm._s(_vm.uploadText) + " "
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass:
                                                "fileUpload__container",
                                              attrs: {
                                                "fill-height": "",
                                                "pa-1": "",
                                                wrap: ""
                                              }
                                            },
                                            [
                                              _c("MiFileUpload", {
                                                ref: "fileUpload",
                                                attrs: {
                                                  filePath: _vm.uploadedFile,
                                                  clearAfterUpload: true
                                                },
                                                on: {
                                                  itemDropped: function(
                                                    $event
                                                  ) {
                                                    return _vm.handleItemDroppedHistorical(
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm.planningOrganizationDialogOpen
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500" },
                    model: {
                      value: _vm.planningOrganizationDialogOpen,
                      callback: function($$v) {
                        _vm.planningOrganizationDialogOpen = $$v
                      },
                      expression: "planningOrganizationDialogOpen"
                    }
                  },
                  [
                    _c("PlanningOrganizationDialog", {
                      attrs: { report: _vm.reportModel },
                      model: {
                        value: _vm.planningOrganizationDialogOpen,
                        callback: function($$v) {
                          _vm.planningOrganizationDialogOpen = $$v
                        },
                        expression: "planningOrganizationDialogOpen"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }